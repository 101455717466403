import { FC, ReactNode, useContext, useMemo } from "react";
import Link from "next/link";
import { FeedContext } from "@finbackoffice/site-core";
import dynamic from "next/dynamic";
import Loading from "components/base/loading/Loading";
import styles from "./sport-games.module.sass";

const PrematchTopWidget = dynamic(
    () => import("components/pages/sport/prematch/top/PrematchTopWidget"),
    {
        // loading: () => <PreloadComponent style={{ height: 190, marginBottom: 8 }} />,
        ssr: false,
    },
);

const PrematchUpcomingWidget = dynamic(
    () => import("components/pages/sport/prematch/upcoming/widget/PrematchUpcomingWidget"),
    {
        // loading: () => <PreloadComponent style={{ height: 190, marginBottom: 8 }} />,
        ssr: false,
    },
);

type IProps = {
    variant: "prematch_top" | "prematch_upcoming";
    icon: ReactNode;
    title: string;
    href: string;
    hoursRange: number;
    onSeeAllClick?: () => void;
};

const SportGames: FC<IProps> = ({ icon, title, href, variant, hoursRange, onSeeAllClick }) => {
    const { prematchIsLoading } = useContext(FeedContext);

    const renderContent = useMemo(() => {
        switch (variant) {
            case "prematch_top":
                return <PrematchTopWidget hoursRange={hoursRange} />;
            case "prematch_upcoming":
                return <PrematchUpcomingWidget hoursRange={hoursRange} />;
            default:
                return null;
        }
    }, [hoursRange, variant]);

    return (
        <div className={styles.sportGames}>
            <div className={styles.header}>
                <span>
                    {icon}
                    {title}
                </span>
                <Link href={href} onClick={onSeeAllClick}>
                    See all
                </Link>
            </div>
            {prematchIsLoading ? <Loading /> : renderContent}
        </div>
    );
};

export default SportGames;
